@import "src/styles";

.overlay {
  position: fixed;
  inset: 0;
  z-index: 100;
  background-color: $accountAlreadyExistsModalOverlayBg;

  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;
    padding: 30px 20px 20px;
    background-color: $accountAlreadyExistsModalBg;
    border-radius: 40px 40px 0 0;
    border: 0;
    outline: none;
    max-width: $maxWidthDefault;

    .subtitle {
      margin-top: -15px;
      color: $createAccountEmailExistPopupTextColor;
    }

    .title {
      font-size: $fontSize26;
    }

    .contactUsWrapper {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 8px;

      .text {
        color: $createAccountEmailExistPopupTextColor;

        .highlighted {
          color: $primaryColor;
          text-decoration: underline;
        }
      }
    }
  }
}